/*=========================================================================================
  File Name: modulePatientActions.js
  Description: Patient Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {
    AddPatient({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/Patient/AddPatient", item)
                .then(response => {
                    commit(
                        "ADD_ITEM",
                        Object.assign(item, { ID: response.data.Data.ID })
                    );
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    SearchPatients({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/Patient/SearchPatients", item)
                .then(response => {
                    debugger
                    commit("SET_Patient", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    SearchGeneralBooking(context, item) {
        debugger
        return new Promise((resolve, reject) => {
            axios
                .post("/api/Patient/SearchGeneralBooking", item)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    SearchPatientsPaging({ commit }, item) { 
        return new Promise((resolve, reject) => {
            axios
                .post("/api/Patient/SearchPatients", item)
                .then(response => {
                    var obj = {};
                    obj.Patients = response.data.Data;
                    obj.PageNumber = item.PageNumber;
                    debugger;
                    commit("SET_PatientPaging", obj);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    CancelSession(context, session) {
        return new Promise((resolve, reject) => {
            axios.post("API/PatientReservationSession/ChangePatientReservationSessionStatus", session)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    SearchReservationPackages({ commit }, item) {
        debugger
        return new Promise((resolve, reject) => {
            axios
                .post("/api/ReservedMedicalPackage/SearchReservedPackage", item)
                .then(response => {
                    commit("SET_ReservedMedicalPackage", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetProfitDataReport({ commit }, item) {
        debugger
        return new Promise((resolve, reject) => {
            axios
                .post("/api/PatientReservationSession/GetProfitDataReport", item)
                .then(response => {
                    debugger
                    commit("SET_ProfitData", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getPendingMedicalDocumentation({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientMedicalReportRequest/SearchPatientMedicalReportRequests",
                    item
                )
                .then(response => {
                    commit("SET_PendingMedicalDocumentations", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getPatientReservationSurgery({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientReservationSurgery/SearchPatientReservationSurgerys",
                    item
                )
                .then(response => {
                    commit("SET_PatientReservationSurgeries", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UpdatePatient(context, item) {
        debugger
        return new Promise((resolve, reject) => {
            axios
                .post("/api/Patient/UpdatePatient", item)
                .then(response => {
                    // commit("UPDATE_Patient", item);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UpdatePatientReservationSessions(context, item) {
        debugger;
        return new Promise((resolve, reject) => {
            axios
                .put("/api/PatientReservationSession/UpdatePatientReservisionSession?patientID=" + item)
                .then(response => {
                    // commit("UPDATE_Patient", item);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    GetPatient({ commit }, itemid) {
        debugger;
        return new Promise((resolve, reject) => {
            axios
                .get("/api/Patient/GetPatientByID?patientID=" + itemid)
                .then(response => {
                    resolve(response);
                   
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetPatientMedicalHistory(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    "/API/PatientMedicalHistory/GetPatientMedicalHistory?patientID=" +
                    item
                )
                .then(response => {
                    // commit("REMOVE_ITEM", item.ID);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetPatientReportAnalysis(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientMedicalHistoryAnalysis/SearchPatientMedicalHistoryAnalysis",
                    item
                )
                .then(response => {
                    // commit("REMOVE_ITEM", item.ID);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetPatientReport(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "API/PatientMedicalHistoryReport/SearchPatientMedicalHistoryReports",
                    item
                )
                .then(response => {
                    // commit("REMOVE_ITEM", item.ID);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetPatientScans(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientMedicalHistoryScan/SearchPatientMedicalHistoryScans",
                    item
                )
                .then(response => {
                    // commit("REMOVE_ITEM", item.ID);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    DeletePatient({ commit }, item) {
        debugger
        return new Promise((resolve, reject) => {
            axios
                .delete("/api/Patient/DeletePatient?patientID=" + item)
                .then(response => {
                    commit("REMOVE_ITEM", item);
                    debugger
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetSurgery(context, itemid) {
        return new Promise((resolve, reject) => {
            axios
                .get("/API/Surgery/GetSurgery?ID=" + itemid)
                .then(response => {
                    resolve(response);
                    // commit("REMOVE_ITEM", itemid);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetPatientForMainData({ commit }, itemid) {
        debugger;
        return new Promise((resolve, reject) => {
            axios
                .get("/api/Patient/GetPatientForMainData?patientID=" + itemid)
                .then(response => {
                    resolve(response);

                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UpdatePatientReservationSession(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientReservationSession/UpdatePatientReservationSession",
                    item
                )
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    AddPatientMedicalDocument({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/Patient/AddPatientMedicalDocument",
                    item
                )
                .then(response => {
                    commit("delete_PendingMedicalDocumentations", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getPatientPendingList({ commit }, patientID) {
        debugger;
        return new Promise((resolve, reject) => {
            axios
                .get(
                    "/API/Patient/GetAllPatientPendingAppointmentAndSurgery?patientID=" + patientID
                )
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};